import React, { useState } from "react";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import contactInfo from "data/Digital/contact.json";

const Contact = () => {
  const [formData, setFormdata] = useState({
    name: "",
    email: "",
    option: "",
    message: "",
  });

  const handleFormChange = (e) => {
    setFormdata((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formValues = new FormData();

    formValues.append("name", formData.name);
    formValues.append("email", formData.email);
    formValues.append("option", formData.option);
    formValues.append("message", formData.message);

    const res = await axios
      .post("/contact.php", formValues)
      .catch((err) => alert(err.message));

    if (!res) return;

    alert("Form submitted successfully.");
  };

  return (
    <section
      className="contact section-padding bg-gradient style-1"
      data-scroll-index="7"
    >
      <div className="container">
        <div className="section-head mb-60 text-center">
          <h6 className="text-white text-uppercase wow fadeInUp">contact us</h6>
          <h2 className="wow fadeInUp text-white">
            <span className="fw-normal">Schedule Free</span> Consultation
          </h2>
        </div>
        <div className="content">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="contact_info text-white">
                <p className="wow fadeInUp">Hotline 24/7</p>
                <h4 className="wow fadeInUp">{contactInfo.phone}</h4>
                <ul>
                  <li className="wow fadeInUp">
                    <strong>Address : </strong> {contactInfo.address}
                  </li>
                  <li className="wow fadeInUp">
                    <strong>Email : </strong> {contactInfo.email}
                  </li>

                  <li className="wow fadeInUp">
                    <strong>Work Hour : </strong> {contactInfo.workingHours}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <form
                className="contact_form"
                action="contact.php"
                method="post"
                onSubmit={handleFormSubmit}
              >
                <div className="row gx-3">
                  <div className="col-lg-6">
                    <div className="form-group mb-3 wow fadeInUp">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Name *"
                        onChange={handleFormChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-3 wow fadeInUp">
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        placeholder="Email Address *"
                        onChange={handleFormChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-3 wow fadeInUp">
                      {/* <select name="option" className="form-select" aria-label="Default select example" defaultValue="Your inquiry about" onChange={handleFormChange}>
                        <option>Country</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select> */}
                      <PhoneInput country="us" inputStyle={{ width: "100%" }} />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-3 wow fadeInUp">
                      <input
                        type="text"
                        name="subject"
                        className="form-control"
                        placeholder="Subject *"
                        onChange={handleFormChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-3 wow fadeInUp">
                      <textarea
                        className="form-control"
                        name="message"
                        rows="4"
                        placeholder="Write your inquiry here"
                        onChange={handleFormChange}
                      ></textarea>
                    </div>
                  </div>
                  {/* <div className="col-lg-12">
                    <div className="form-check mb-4 wow fadeInUp">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                      <label className="form-check-label text-light small" htmlFor="flexCheckDefault">
                        By submitting, i’m agreed to the <a href="#" className="text-decoration-underline">Terms & Conditons</a>
                      </label>
                    </div>
                  </div> */}
                  <div className="col-lg-12">
                    <input
                      type="submit"
                      value="Request Now"
                      className="btn btn-dark wow fadeInUp text-light fs-14px"
                    ></input>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <img
        src="/assets/img/contact_globe.svg"
        alt=""
        className="contact_globe"
      />
    </section>
  );
};

export default Contact;
