import React, { useState } from "react";
import { Link } from "gatsby";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";

const Header = () => {
  const [isOpen, setOpen] = useState(false);

  const openVideo = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  return (
    <header className="section-padding style-1" data-scroll-index="0">
      <div className="container">
        <div className="content">
          <div className="row">
            <div className="col-lg-6">
              <div className="info">
                <div className="section-head mb-60">
                  <h6 className="color-main text-uppercase">
                    Leading Edge Business Solutions
                  </h6>
                  <h2>
                    Your Journey to Success Starts Here
                    <span className="fw-normal"></span>
                  </h2>
                </div>
                <div className="text">
                  At Leading Edge Business Solutions, we redefine success
                  through cutting-edge strategies and solutions.
                </div>
                <div className="bttns mt-5">
                  <Link to="/page-services-5" className="btn btn-dark">
                    <span>our services</span>
                  </Link>
                  <a
                    href="https://www.youtube.com/watch?v=sthMt1lCORM"
                    className="vid-btn"
                    onClick={openVideo}
                  >
                    <i className="bi bi-play wow heartBeat infinite slow"></i>
                    <span>
                      LEBS <br /> Showreels
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1">
              <div className="img">
                <img src="/assets/img/header/head.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <img src="/assets/img/header/header_sape_rr.png" alt="" className="head-shape-r wow" /> */}
      <img
        src="/assets/img/header/head_shape_r.png"
        alt=""
        className="head-shape-r wow"
      />
      <img
        src="/assets/img/header/head_shape_l.png"
        alt=""
        className="head-shape-l wow"
      />
      {typeof window !== "undefined" && (
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="sthMt1lCORM"
          onClose={() => setOpen(false)}
        />
      )}
    </header>
  );
};

export default Header;
